<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Settings</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-toolbar flat dense dark color="accent">
              <v-toolbar-title>Website Functionality Toggles</v-toolbar-title>
            </v-toolbar>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Are Trial Bags Currently Allowed?</th>
                  <td>
                    <v-switch
                      inset
                      color="green"
                      v-model="allow_trial_bags"
                      :label="allow_trial_bags ? 'Yes' : 'No'"
                      :loading="allow_trial_bags_loading"
                      @click="updateSetting('allow_trial_bags')"
                    ></v-switch>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Settings",
          disabled: true,
        },
      ],
      allow_trial_bags: false,
      allow_trial_bags_loading: false,
    };
  },

  computed: {
    settings() {
      return this.$store.getters["paw/settingsStore/all"];
    },
  },

  mounted() {
    if (this.settings.some((s) => s.name === "allow_trial_bags")) {
      const setting = this.settings.find((s) => s.name === "allow_trial_bags");

      this.allow_trial_bags = setting.content == "1" ? true : false;
    }
  },

  methods: {
    updateSetting(name) {
      const appId = this.$route.params.id;
      this[name + "_loading"] = true;

      this.$store
        .dispatch("paw/settingsStore/updateSettings", {
          appId,
          fields: {
            name,
            content: this[name] ? "1" : "0",
          },
        })
        .then(() => (this[name + "_loading"] = false));
    },
  },
};
</script>
